<template>
	<div class="article__view">
		<div class="header">
			<h2>Inscription du site à un annuaire</h2>
			<v-btn v-if="$route.name == 'annuaireInscriptionEdit'" color="white elevation-0" :loading="loadingDelete" class="white--text" @click="dialog = true; toDelete = annuaireInscription" icon >
				<v-icon color="red lighten-1">
					mdi-trash-can-outline
				</v-icon>				
			</v-btn>
			<v-dialog v-model="dialog" width="300">
				<v-card>
					<v-card-text style="padding-top: 20px">
						<h3 class="text-center">Voulez-vous vraiment supprimer ?</h3>
					</v-card-text>
					<v-card-actions>
						<v-btn color="primary" text @click="dialog = false">
							Annuler
						</v-btn>
						<v-spacer></v-spacer>
						<v-btn v-if="site.user > 0" color="red darken-3" :loading="loadingDelete" text @click="deleteAnnuaireInscription()">
							Supprimer
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<div class="control">
			<v-btn v-if="site.user > 0" color="#272727" class="white--text" @click="retour()" tile x-large>
				Retour
			</v-btn>
			<v-btn v-if="$route.name == 'annuaireInscriptionEdit' && annuaireInscription.id > 0 && annuaireInscription.status != 'publish'" color="green" class="white--text" :loading="loading" @click="updateAnnuaireInscription()" tile x-large>
				Sauvegarder
			</v-btn>
			<v-btn v-else-if="$route.name == 'annuaireInscriptionAdd' && site.id > 0 && annuaireInscription.status != 'publish'" color="green" class="white--text" :loading="loading" @click="addAnnuaireInscription()" tile x-large >
				Sauvegarder
			</v-btn>
		</div>
		<div class="displayer">
			<div class="right">
				<div class="article__card">
					<div class="article">
						<div v-if="$route.name == 'annuaireInscriptionEdit'">
							<h3 v-if="annuaireInscription.status == 'publish'">Le site est publié sur l'annuaire</h3>
							<br>
						</div>
						<v-form
							ref="form"
							v-model="valid"
							lazy-validation
						>
							<v-select
								v-if="$route.name == 'annuaireInscriptionAdd'"
								v-model="annuaireInscription.annuaire"
								:items="annuaires"
								filled
								item-text="name"
								item-value="id"
								:rules="annuaireRules"
								label="Annuaire"
							></v-select>
							<div v-else-if="$route.name == 'annuaireInscriptionEdit'">
								<b>Annuaire</b>
								<p><a :href="getBaseUrl(annuaireInscription.annuaire.url)" target="_blank">{{ annuaireInscription.annuaire.name }}</a></p>
							</div>
							<br>
							<div v-if="annuaireInscription.status == 'publish'">
								<b>Description</b>
								<p>{{ annuaireInscription.content }}</p>
								<br>
							</div>
							<div v-else>
								<v-textarea label="Description" :rules="contentRules" v-model="annuaireInscription.content"></v-textarea>
							</div>
							<div v-if="annuaireInscription.file > 0">
								<v-img :src="urlImage"></v-img>
								<v-btn v-if="annuaireInscription.status != 'publish'" color="red darken-3" :loading="loadingDeleteImage" class="white--text" @click="deleteImageLocal()" block :disabled="annuaireInscription.status == 'publish'">Supprimer l'image</v-btn>
							</div>
							<div v-else class="upload__image">
								<div v-if="annuaireInscription.status != 'publish'" class="displayer">
									<div class="upload__gallery">
										<h4>Charger une image</h4>
										<v-file-input accept="image/png, image/jpeg" placeholder="Choisir une image" truncate-length="15" v-model="uploadImage" @change="uploading()" counter show-size :disabled="annuaireInscription.status == 'publish'"></v-file-input>
										<v-progress-linear class="loading__file" indeterminate color="primary" v-if="loadingUpload"></v-progress-linear>
									</div>
								</div>
								<h4 v-if="urlImage != ''">{{ urlImage }}</h4>
								<v-img v-if="urlImage != ''" :src="urlImage"></v-img>
							</div>
						</v-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import _ from 'lodash';
import { utils } from '../utils.js'

export default {
	name: 'AnnuaireInscription',
	data() {
		return {
			valid: false,
			annuaireRules: [
				v => !!v || 'Champs requis'
			],
			contentRules: [
				v => !!v || 'Champs requis'
			],
			annuaireInscription: {
				id: null,
				annuaire: {
					id: null,
					name: '',
					url: ''
				},
				site: null,
				content: '',
				status: 'draft',
				file: null
			},
			site: {
				id: 0,
				user: 0
			},
			annuaires: [],
			urlImage: '', // l'url qui sert uniquement à l'affichage
			idImageTempo: 0,
			uploadImage: null, // Le file envoyé pour l'upload d'image
			loadingDeleteImage: false,
			toDelete: {},
			dialog: false,
			loading: false,
			loadingDelete: false,
			loadingUpload: false
		}
	},
	watch: {
		uploadImage: function() {
			if (this.uploadImage == null) {
				this.urlImage = ''
				return
			}
			this.urlImage = URL.createObjectURL(this.uploadImage)
		}
	},
	methods: {
		...mapActions("user", [
			"getUserAction"
		]),
		...mapActions("global", [
			"displayFileAction",
			"addFileAction",
			"deleteFileAction",
			"getAnnuairesAction",
			"getAnnuaireInscriptionAction",
			"addAnnuaireInscriptionAction",
			"updateAnnuaireInscriptionAction",
			"deleteAnnuaireInscriptionAction",
			"getSiteAction"
		]),
		...mapMutations("global", [
			"addSuccess",
			"addErrors"
		]),
        getBaseUrl(url) {
            let urlObject = new URL(url);
            return urlObject.origin
        },
		retour() {
			this.deleteImage()
			this.$router.push({ path: '/user/' + this.site.user });
		},
		deleteImageLocal() {
			this.annuaireInscription.file = null
			this.urlImage = '';
		},
		deleteImage() {
			if (this.idImageTempo < 1) {
				return
			}

			const _this = this;
			this.deleteFileAction(this.idImageTempo)
			.finally(() => {
				_this.loadingUpload = false;
				this.idImageTempo = 0;
			})			
		},
		uploading() {
			const _this = this;
			this.loadingUpload = true;
			this.deleteImage()

			this.addFileAction({
				user: this.site.user,
				uploadImage: this.uploadImage
			})
			.then(res => {
				_this.annuaireInscription.file = res.data.id
				_this.idImageTempo = res.data.id
			})
			.finally(() => {
				_this.loadingUpload = false;
			})
		},
		updateAnnuaireInscription() {
			if (this.$refs.form.validate() == false) {
				return
			}

			const _this = this;
			this.loading = true;
			this.updateAnnuaireInscriptionAction({
				annuaireInscription: this.annuaireInscription.id,
				status: this.annuaireInscription.status,
				content: this.annuaireInscription.content,
				file: this.annuaireInscription.file
			})
			.then(() => {
				_this.idImageTempo = 0
				_this.addSuccess("Les informations de l'inscription à l'annuaire a été mis à jour.")
				_this.retour()
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loading = false
			})
		},
		addAnnuaireInscription() {
			if (this.$refs.form.validate() == false) {
				return
			}

			const _this = this
			this.loading = true
			this.addAnnuaireInscriptionAction({
				status: this.annuaireInscription.status,
				content: this.annuaireInscription.content,
				file: this.annuaireInscription.file,
				site: this.site.id,
				annuaire:  this.annuaireInscription.annuaire
			})
			.then((res) => {
				_this.idImageTempo = 0

				if (res.data.status == 'draft') {
					_this.addSuccess("L'inscription à l'annuaire a été ajoutée en prépublication sur Maax.")
				} else {
					_this.addSuccess("Le site a été directement inscrit à l'annuaire.")
				}

				_this.retour()
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loading = false
			})
		},
		deleteAnnuaireInscription() {
			const _this = this;
			this.loadingDelete = true;
			this.deleteAnnuaireInscriptionAction(this.toDelete.id)
			.then(() => {
				_this.addSuccess("L'inscription à l'annuaire a été supprimée.")
				_this.retour()
			})
			.finally(() => {
				_this.loadingDelete = false
				_this.toDelete = {}
				_this.dialog = false;
			})
		},
		loadAnnuaireInscription() {
			const _this = this
			this.getAnnuaireInscriptionAction(this.$route.params.annuaireInscriptionId)
			.then((res) => {
				_this.annuaireInscription.id = res.data.id
				_this.annuaireInscription.titre = res.data.titre
				_this.annuaireInscription.content = res.data.content
				_this.annuaireInscription.annuaire.id = res.data.annuaire.id
				_this.annuaireInscription.annuaire.name = res.data.annuaire.name
				_this.annuaireInscription.annuaire.url = res.data.annuaire.url
				_this.annuaireInscription.status = res.data.status
				_this.site.id = res.data.site
				_this.site.url = res.data.url
				_this.annuaireInscription.file = res.data.file
				_this.loadFile()

				return this.getSiteAction(res.data.site)
			})
			.then((res) => {
				_this.site.user = res.data.user
			})

		},
		loadFile() {
			if (this.annuaireInscription.file < 1) {
				return
			}

			const _this = this
			this.displayFileAction(this.annuaireInscription.file)
			.then((res) => {
				_this.urlImage = res
			})
		},
		loadUser() {
			const _this = this
			this.getUserAction(this.$route.params.userId)
			.then(function (res) {
				_this.site.user = res.data.id
				_this.site.id = res.data.site.id
			})
		},
		loadAnnuaires() {
			const _this = this
			this.getAnnuairesAction()
			.then(res => {
				_this.annuaires = res.data
				_this.annuaires.sort(function (a, b) {
					var nameA = _.lowerCase(_.deburr(a.name));
					var nameB = _.lowerCase(_.deburr(b.name));
					return (nameA > nameB) ? 1 : -1;
				})
			})
		}
	},
	created() {
		if (this.$route.name === 'annuaireInscriptionEdit') {
			this.loadAnnuaireInscription()
		} else if (this.$route.name === 'annuaireInscriptionAdd') {
			this.loadAnnuaires()
			this.loadUser()
		}
	}
}
</script>

<style lang="scss" scoped>
.article__view {
	min-height: 100vh;

	.header {
		display: flex;
		align-items: center;
		padding: 20px;
		border-bottom: 1px solid #dbdbdb;

		h2 {
			font-weight: normal;
			margin-bottom: 20px;
			width: 100%;
			margin: 0;

			span {
				font-size: 16px;
				color: #969696;
			}
		}
	}

	.control {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 100;

		.v-btn {
			width: 50%;
		}
	}

	.displayer {
		display: flex;
		height: 100%;

		.left {
			width: 25%;
			min-height: 100vh;
			border-right: 1px solid #dbdbdb;
		}

		.right {
			width: 75%;
			padding: 1% 2% 5% 2%;
			height: fit-content;

			.article__card {
				padding: 10px;

				h4 {
					color: #c1c1c1;
					font-weight: normal;
				}

				.article {
					margin-top: 2%;

					.swicth__status {
						display: flex;
						align-items: center;

						h3 {
							margin-right: 10px;
						}
					}

					.info__image {
						margin-top: 15px;
					}

					.upload__image {
						margin-top: 25px;
						.displayer {
							display: flex;
							gap: 50px;
						}

						.grid__gallery {
							width: 50%;
							border-right: 2px dashed #c7c7c7;
							min-height: 250px;

							.row {
								padding: 10px;
							}

							.gallery {
								cursor: pointer;
								position: relative;

								.selected {
									position: absolute;
									top: 0;
									right: 0;
									border-radius: 50%;
									background: green;
									z-index: 2;
									width: 25px;
									height: 25px;
									display: flex;
									align-items: center;
									justify-content: center;

									i {
										font-size: 15px;
									}
								}
							}
						}

						.upload__gallery {
							width: 50%;
						}

						.loading__file {
							margin-top: 50px;
						}
					}

				}
			}
		}
	}
}
</style>